import script from "./ConfigProfile.vue?vue&type=script&lang=ts&setup=true"
export * from "./ConfigProfile.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QForm,QInput,QSeparator,QCardActions,QBtn,QSpace});
